<form [formGroup]="addEditTreatmentForm" class="col-12 p-0">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="input-group w-100">
                <mat-form-field class="w-100" (click)="productsModal.showModal(); show = true">
                    <mat-label>{{"stock.product" | translate}}</mat-label>
                    <input style="display: none;" type="text" matInput formControlName="label" readonly="true" name="label">
                    <span style="min-height: 16.8px; display: inline-block; cursor: pointer;">
                        {{label?.name}}
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="input-group align-items-baseline w-100">
                <fap-add-edit-delete-select 
                    [inputName]="'productsUsage.type' | translate"
                    class="w-100"
                    [formControl]="addEditTreatmentForm.controls['type']"
                    name="type"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="productUsageTypes"
                    [inputConfirmDeleteText]="'productsUsage.deleteProductUsageTypeMessage' | translate"
                    (outputOnCreate)="showProductUsageTypeModal(true); closeCombo = false;"
                    (outputOnEdit)="productUsageTypeEdit($event); closeCombo = false;"
                    (outputOnDelete)="productUsageTypeDelete($event);"
                    [closeCombo]="closeCombo"
                    (clicked)="verifyFormType($event)"
                    (selectionChange)="addEditTreatmentForm.get('type').setValue($event)"
                    >
            </fap-add-edit-delete-select>
            </div>
           
            <!-- <div class="w-100 pos_rel">
                <label class="col-form-label float">{{ 'productsUsage.type' | translate }}</label>
            <chips 
                [items]="productUsageTypes" 
                [selectedItems]="selectedTypes"
                [label]="'productsUsage.type' | translate"
                (updatedItems)="updateTypes($event)"
                [addEnabled]="true"
                [isEditable]="true"
                [isDeleteable]="true"
                (customEditEmit)="productUsageTypeEdit($event)"
                (editItem)="productUsageTypeEdit($event)"
                (deleteItem)="deleteProductUsageType($event)"
                (showAddModal)="showProductUsageTypeModal(true)"
                >
            </chips>
            </div> -->
        </div>
        <div class="min_number col-lg-12 col-md-12 col-sm-12">
            <div class="range_number_unit d-flex with_units">
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'productsUsage.quantityMin' | translate}}</mat-label>
                        <input type="number" style="text-align: right;" matInput formControlName="quantity_min" (change)="validateQuantityMinMax()">
                    </mat-form-field>
                </div>
                <div class="divider"><div>-</div></div>
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'productsUsage.quantityMax' | translate}}</mat-label>
                        <input type="number" style="text-align: right;" matInput formControlName="quantity_max" (change)="validateQuantityMinMax()">
                    </mat-form-field>
                </div>
                <div class="w5 p-0 mt-20">
                    <div class="unit_value">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>{{ 'units.unit' | translate }}</mat-label>
                            <mat-select formControlName="quantity_unit">
                                <div class="d-flex" style="align-items: center; padding: 0 10px;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #resourceFilter placeholder="Search">
                                </mat-form-field>
                                <fap-round-button (clicked)="selectUnit()" [backgroundColor]="'tertiary'"></fap-round-button>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                <mat-optgroup *ngFor="let parent of globalRegistry?.systemData?.units | filter : resourceFilter.value">
                                    <span class="mat-option-text">
                                        <div class="type-name">
                                            {{ parent.nameT ? getTranslation(parent.nameT) : parent.name }}
                                        </div>
                                        <div class="d-flex text-center">
                                            <action-buttons 
                                                [locked]="parent?.locked" 
                                                (editEmit)="selectUnit(parent)" 
                                                (deleteEmit)="onDeleteUnit(parent)"
                                            ></action-buttons>
                                        </div>
                                    </span>
                                    <mat-option *ngFor="let child of parent.children" [value]="child.id">
                                        <div class="type-name">
                                            {{ child.shortName }}
                                        </div>
                                        <div class="d-flex text-center">
                                            <action-buttons 
                                                [locked]="child?.locked" 
                                                (editEmit)="selectUnit(child)" 
                                                (deleteEmit)="onDeleteUnit(child)"
                                            ></action-buttons>
                                        </div>
                                    </mat-option>
                                </mat-optgroup>
                            </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <fap-unit-value class="half_width"
                [fieldName]="'productsUsage.appliedTo' | translate"
                [(ngModel)]="applyValue"
                [ngModelOptions]="{standalone: true}">
            </fap-unit-value>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <fap-unit-value class="half_width"
                [fieldName]="'productsUsage.waterDilution' | translate"
                [(ngModel)]="dilutionValue"
                [ngModelOptions]="{standalone: true}">
            </fap-unit-value>
        </div>
        <div class="min_number col-lg-12 col-md-12 col-sm-12">
            <div class="range_number_unit d-flex with_units">
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'productsUsage.repauseMin' | translate}}</mat-label>
                        <input type="number" style="text-align: right;" matInput formControlName="repause_min" (change)="validateRepauseMinMax()">
                    </mat-form-field>
                </div>
                <div class="divider"><div>-</div></div>
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'productsUsage.repauseMax' | translate}}</mat-label>
                        <input type="number" style="text-align: right;" matInput formControlName="repause_max" (change)="validateRepauseMinMax()">
                    </mat-form-field>
                </div>
                <div class="w5 p-0 mt-20">
                    <div class="unit_value">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>{{ 'units.unit' | translate }}</mat-label>
                            <mat-select formControlName="repause_unit">
                                <div class="d-flex" style="align-items: center; padding: 0 10px;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #resourceFilter placeholder="Search">
                                </mat-form-field>
                                <fap-round-button (clicked)="selectUnit()" [backgroundColor]="'tertiary'"></fap-round-button>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                <mat-optgroup *ngFor="let parent of globalRegistry?.systemData?.units | filter : resourceFilter.value">
                                    <span class="mat-option-text">
                                        <div class="type-name">
                                            {{ parent.nameT ? getTranslation(parent.nameT) : parent.name }}
                                        </div>
                                        <div class="d-flex text-center">
                                            <action-buttons 
                                                [locked]="parent?.locked" 
                                                (editEmit)="selectUnit(parent)" 
                                                (deleteEmit)="onDeleteUnit(parent)"
                                            ></action-buttons>
                                        </div>
                                    </span>
                                    <mat-option *ngFor="let child of parent.children" [value]="child.id">
                                        <div class="type-name">
                                            {{ child.shortName }}
                                        </div>
                                        <div class="d-flex text-center">
                                            <action-buttons 
                                                [locked]="child?.locked" 
                                                (editEmit)="selectUnit(child)" 
                                                (deleteEmit)="onDeleteUnit(child)"
                                            ></action-buttons>
                                        </div>
                                    </mat-option>
                                </mat-optgroup>
                            </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="min_number col-lg-12 col-md-12 col-sm-12">
            <div class="range_number_unit d-flex with_units">
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'productsUsage.reactionMin' | translate}}</mat-label>
                        <input type="number" style="text-align: right;" matInput formControlName="reaction_min">
                    </mat-form-field>
                </div>
                <div class="divider"><div>-</div></div>
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'productsUsage.reactionMax' | translate}}</mat-label>
                        <input type="number" style="text-align: right;" matInput formControlName="reaction_max">
                    </mat-form-field>
                </div>
                <div class="w5 p-0 mt-20">
                    <div class="unit_value">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>{{ 'units.unit' | translate }}</mat-label>
                            <mat-select formControlName="reaction_unit">
                                <div class="d-flex" style="align-items: center; padding: 0 10px;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #resourceFilter placeholder="Search">
                                </mat-form-field>
                                <fap-round-button (clicked)="selectUnit()" [backgroundColor]="'tertiary'"></fap-round-button>
                                </div>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                <mat-optgroup *ngFor="let parent of globalRegistry?.systemData?.units | filter : resourceFilter.value">
                                    <span class="mat-option-text">
                                        <div class="type-name">
                                            {{ parent.nameT ? getTranslation(parent.nameT) : parent.name }}
                                        </div>
                                        <div class="d-flex text-center">
                                            <action-buttons 
                                                [locked]="parent?.locked" 
                                                (editEmit)="selectUnit(parent)" 
                                                (deleteEmit)="onDeleteUnit(parent)"
                                            ></action-buttons>
                                        </div>
                                    </span>
                                    <mat-option *ngFor="let child of parent.children" [value]="child.id">
                                        <div class="type-name">
                                            {{ child.shortName }}
                                        </div>
                                        <div class="d-flex text-center">
                                            <action-buttons 
                                                [locked]="child?.locked" 
                                                (editEmit)="selectUnit(child)" 
                                                (deleteEmit)="onDeleteUnit(child)"
                                            ></action-buttons>
                                        </div>
                                    </mat-option>
                                </mat-optgroup>
                            </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="label_descriptions w-100 mb-4">
            <fap-info-sections [descriptions]="descriptions" [isEditable]="true" (updatedDescription)="updateDescription($event)"></fap-info-sections>
        </div>
    </div>
    <div class="form-group row mt-3" *ngIf="treatment?.type">
        <fap-attributes style="width: -webkit-fill-available;"
            [entityRelation]="'product_usage'"
            [entityId]="entityInfo?.id"
            [objectId]="treatment?.id"
            [entityInfo]="entityInfo"
            (formTypeEmit)="formType = $event"
            (formFieldsEmit)="formFields = $event"
            >
        </fap-attributes>
    </div>
    <div class="btns">
        <div class="action_btns d-flex justify-content-end mt-3">
            <button class="btn mr-2" (click)="submitTreatment()">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="addEditTreatmentForm.reset(); closeModal.emit()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </div>
</form>


<fap-modal #addEditUnitModal
    inputTitle="{{ 'units.addEditUnit' | translate }}">
    <fap-add-edit-unit
        [selectedUnit]="selectedUnit"
        [units]="globalRegistry?.systemData?.units"
        [parentUnit]="parentUnit"
        (cancel)="addEditUnitModal.hideModal"
        (unitActionCompleted)="onUnitAction($event)">
    </fap-add-edit-unit>
</fap-modal>

<fap-modal #addEditProductModal
[inputTitle]="'product.addEditProduct' | translate" (outputOnHide)="labelId = null">
        <fap-add-edit-product-label 
            [labelId]="labelId"
            (closeModal)="addEditProductModal.hideModal()"
            (addedLabel)="addProductAction($event);addEditProductModal.hideModal()"
            (updatedLabel)="updateProductAction($event);addEditProductModal.hideModal()">
        </fap-add-edit-product-label>
</fap-modal>

<fap-modal #productsModal [inputIsLarge]="true"
[inputTitle]="'stock.products' | translate" [showFooter]="false">
    <products-label-modal (hideModal)="hideProducts()" (selectedLabel)="assignProduct($event)" [show]="show"></products-label-modal>
</fap-modal>

<fap-modal
        #addEditProductUsageTypeModal
        [inputPrimaryButtonInterface]="
            addProductUsageTypeModalButtonPrimaryInterface
        "
        [inputSecondaryButtonInterface]="
            addProductUsageTypeModalButtonSecondaryInterface
        "
        [inputTitle]="'productsUsage.addEditProductUsageType' | translate"
    >
        <form [formGroup]="productUsageTypeForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput', productUsageType, addEditTranslationsModal, 'productUsageTypeForm')">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput/>
                    <mat-error *ngIf="productUsageTypeForm.controls['name'].errors?.required">
                      <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <fap-add-edit-delete-select
                    inputName="{{ 'stock.formType' | translate }}"
                    class="w-100"
                    [formControl]="productUsageTypeForm.controls['entity_form']"
                    name="formType"
                    [inputIsRequired]="false"
                    [apiSearchEnabled]="false"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="formTypes"
                    [closeCombo]="closeCombo"
                    [isEditable]="false"
                    [isDeleteable]="false"
                    [createEnabled]="false"
                    (clicked)="setFormType($event)"
                >
                </fap-add-edit-delete-select>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "notes.color" | translate
                    }}</mat-label>
                    <input
                        class="w-100"
                        matInput
                        type="color"
                        formControlName="color"
                    />
                    <mat-error
                        *ngIf="
                            productUsageTypeForm.controls['color'].errors?.required
                        "
                    >
                        <strong>{{
                            "auth.validation.requiredField" | translate
                        }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="translationLabel | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(productUsageType)" 
        [prefix]="'PRODUCT_USAGE_'" 
        [translation]="translation"
        [group]="'types'"
        [label]="translationLabel"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>