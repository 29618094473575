<div class="add_edit_infosection">
    <form [formGroup]="infosectionForm">
        <div class="row">
            <div class="col-12">
                <div class="input-group w-100">
                    <mat-form-field class="w-100" (click)="addEditTranslation('title', 'nameInput', description, addEditTranslationsModal, 'infosectionForm')">
                        <mat-label class="col-form-label">{{ "title" | translate }}</mat-label>
                        <input matInput formControlName="name" readonly #nameInput/>
                        <mat-error *ngIf="infosectionForm.controls['name'].errors?.required">
                          <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 pos_rel" (click)="addEditBodyTranslation('body', 'nameInput1', description, addEditBodyTranslationModal, 'infosectionForm')">
                <label class="col-form-label float">{{ 'body' | translate }}</label>
                <!-- <div class="NgxEditor__Wrapper cust_wrap" style="min-height: 180px;" (click)="addEditBodyTranslation('body', 'nameInput1', description, addEditBodyTranslationModal, 'infosectionForm')">
                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                    <ngx-editor [editor]="editor" [(ngModel)]="html" [ngModelOptions]="{standalone: true}" [disabled]="false" [placeholder]="'Type here...'" style="height: 100%;"></ngx-editor>
                  </div>                                 -->
                  <div [innerHTML]="getHtmlContent(html)" class="fake_input"></div>
            </div>
            <div class="col-12 mt-4" style="margin-bottom: 1rem!important;">
                <label class="col-form-label float">{{ 'notes.images' | translate }}</label>
                <fap-image-list [imageIds]="descriptionImages" [group]="'activities'" (deleteImage)="deleteInfoImage($event)" (addImage)="addInfoImage($event)"></fap-image-list>
            </div>
            <div class="col-12 mt-4 d-flex justify-content-end">
                <button class="btn mr-2" (click)="submitDescription()">{{ 'translations.save' | translate }}</button>
                <button class="btn" (click)="closeModal.emit()">{{ 'translations.cancel' | translate }}</button>
              </div>
        </div>
    </form>
</div>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(description)" 
        [prefix]="'INFO_SECTION_'" 
        [translation]="translation"
        [group]="'types'"
        [label]="label"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditBodyTranslationModal [inputTitle]="label | translate" [showFooter]="false" [inputIsMediumLarge]="true">
    <add-edit-body-translations 
        [obj]="convertKeysToCamelCase(description)" 
        [prefix]="'INFO_SECTION_'" 
        [translation]="translation"
        [group]="'types'"
        [label]="label"
        [isHTML]="true"
        (submitTranslation)="onAddUpdateBodyTranslation($event)" 
    >
    </add-edit-body-translations>
</fap-modal>